import { render, staticRenderFns } from "./addsale.vue?vue&type=template&id=2253819f&scoped=true"
import script from "./addsale.vue?vue&type=script&lang=js"
export * from "./addsale.vue?vue&type=script&lang=js"
import style0 from "./addsale.vue?vue&type=style&index=0&id=2253819f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2253819f",
  null
  
)

export default component.exports